

.loading {
  width:100%;
  text-align: center;
  display: inline-block;
  position: relative;
  width: 24px;
  height: 24px;
  margin:auto;
  display: flex;
  justify-content: center;
}
.loading div {
  width:100%;
  margin-top: -10px;
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: loading 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.loading div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes loading {
  0% {
    top: 18px;
    left: 18px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 36px;
    height: 36px;
    opacity: 0;
  }
}

  
  

.loading.gray div {
  border: 4px solid #888
}

.debug {
  position: fixed;
  bottom: 0px;
}


.content.blank-page {
  overflow: overlay;
  overflow-x: hidden;
  padding: 0 !important;
  transition: none; 
  margin-left: 0; 
}

.bs-stepper-content {
  height: fit-content;
}


.alert {
  margin-top: 14px;
  padding: 16px;
  text-align: center;
}

.nav-item:not(:first-of-type) {
  display: none;
}

.header-navbar {
  height: 60px;
}

.app-content, .app-ecommerce-details , .ecommerce-application {
  padding-top: 80px !important;
}

.horizontal-menu-wrapper {
  display: none;
}

body {
  overflow-y: scroll;
}

.header-navbar{
  top: 0px;
}

.categories-list li {
  margin-bottom: 0px !important;
}

.inventory {
  height: 30px;
}